@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800;900&display=swap');

@tailwind base;

body {
  @apply text-secondary-800;
}

@tailwind components;
@tailwind utilities;

/* Responsive Logo */
.logo {
  height: 48px;
  width: 48px;
  display: block;
  overflow: hidden;
}

@media (min-width: 768px) {
  .logo {
    height: 48px;
    width: 240px;
  }
}

/* Hamburger menu */
@media (min-width: 768px) {
  label[for="mobile-menu"] {
    display: none !important;
  }
}

@media (max-width: 767px) {
  #mobile-menu + ul {
    display: none;
  }

  #mobile-menu:checked + ul {
    display: flex;
  }
}

/* Sub-navigation */
.subnavigation-menu:not(:hover) {
  display: none;
}

.subnavigation-toggle:active + .subnavigation-menu,
.subnavigation-toggle:focus + .subnavigation-menu {
  display: block;
}
@media (pointer: coarse) {
  .subnavigation-toggle:hover + .subnavigation-menu {
    display: block;
  }
}